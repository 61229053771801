import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Baukonto = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Alles Wichtige zum Thema Baukonto" showCalc={false} />
            <Article>
                <p>
                    Wenn du ein Haus bauen möchtest oder sogar schon damit angefangen hast, könnte ein Baukonto eine
                    sinnvolle Option für dich sein, um alle bevorstehenden Rechnungen rund um dein Projekt damit zu
                    bezahlen.
                </p>
                <hr />

                <h2>Was ist ein Baukonto?</h2>
                <p>
                    Ein Baukonto wird meistens zu Beginn des geplanten Bauprojektes eingerichtet. Es ist ein
                    vergünstigtes Girokonto mit der Möglichkeit zum Überziehen. Es dient hauptsächlich zur
                    Zwischenfinanzierung, während du beispielsweise auf die beantragte Mittel der Wohnbauförderung
                    wartest. So bist du während des Baus immer flüssig und kannst dein Projekt verwirklichen. Wenn die
                    erwarteten Fördergelder eingegangen sind, bleibt vielleicht noch ein Restbetrag übrig, der dann
                    meistens in einen herkömmlichen Bankkredit umgewandelt wird.
                </p>
                <hr />

                <h2>Warum macht es Sinn, ein Baukonto zu haben?</h2>
                <p>
                    Ein Baukonto zu besitzen, lohnt sich, vor allem wenn du während deiner Bauphase für das Wohnprojekt
                    ein hohes Maß an finanzieller Flexibilität brauchst. Das ist eigentlich meistens der Fall, denn
                    anfallende Kosten und auch der Zeitaufwand für die Umsetzung deines Projektes sind immer eine
                    unsichere Sache. Vor allem anfangs kann man die Kosten und den Zeitaufwand noch schwer einschätzen
                    und oft unterschätzt man die Ausgaben hier auch. Wenn ungeplante Dinge geschehen, bist du mithilfe
                    eines Baukontos in der Lage, damit umzugehen.
                </p>
                <hr />

                <h2>Wie funktioniert ein Baukonto?</h2>
                <p>
                    Wenn du ein Haus baust oder auch „nur“ sanierst, fallen die ganze Zeit über Zahlungen an, die dann
                    über dein Baukonto beglichen werden können. Wenn du gerade auf die Auszahlung deiner
                    Wohnbauförderung wartest, können hereinflatternde Rechnungen bereits durch das Baukonto bezahlt
                    werden. Sobald die Wohnbauförderung dann ausbezahlt wurde, fließt sie auf dein Baukonto und dir
                    steht wieder ein höherer Betrag für alle bevorstehenden Zahlungen zur Verfügung.
                </p>
                <p>
                    Auch im Fall einer Zwischenfinanzierung beim Verkauf einer deiner Liegenschaften kannst du in
                    Österreich ein Baukonto verwenden. Wenn du eine Immobilie verkaufst, um das dadurch frei werdende
                    Kapital für ein neues Hausprojekt zu verwenden, ist meistens noch nicht klar, wann du einen Käufer
                    oder eine Käuferin findest und welchen Preis du für deine Immobilie erzielen wirst. Deshalb kann der
                    Erlös bereits über ein Baukonto vorfinanziert werden, damit du nicht unter Druck gerätst. Wenn dann
                    dein Verkaufserlös da ist, wird das Baukonto damit wieder ausgeglichen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Besonderheiten hat das Baukonto?</h2>
                <p>
                    Eine wichtige Besonderheit bei dem Baukonto ist, dass du die Zinsen nur für den genauen Betrag
                    bezahlen musst, den du in Anspruch nimmst. Wenn dein Bauprojekt fertig ist, wird das restliche Geld,
                    wenn noch etwas überbleibt, in einen langfristigen Kredit umgewandelt.
                </p>
                <p>
                    Außerdem wird die Kreditlaufzeit eines Baukontos ziemlich kurz gehalten: meistens zwischen 12 und 48
                    Monaten. In dieser Zeit musst du regelmäßig anfallende Sollzinsen bezahlen, aber keine Tilgungen
                    vornehmen. Am Ende des vereinbarten Zeitraums wird das Konto geschlossen und wenn nötig in einen
                    Kredit umgewandelt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Die Vor- und Nachteile auf einen Blick</h2>
                <p>Ein Baukonto hat viele Vorteile:</p>
                <ul>
                    <li>Flexibilität während der Projektphase</li>
                    <li>Geringe Kontoführungsgebühren</li>
                    <li>
                        Möglichkeiten zur Vor- und Zwischenfinanzierung, um Eigenmittel einer verkaufenden Immobile
                        herauszulösen, ohne dass eine laufenden Kreditrückzahlung nötig wird
                    </li>
                    <li>Steuerbarkeit der monatlichen Liquiditätsbelastung, die Kredittilgung fällt weg</li>
                </ul>
                <p>Aber es gibt auch einige Nachteile, die du kennen solltest:</p>
                <ul>
                    <li>Nicht für große Summen geeignet, sondern für kleinere, die nach und nach bezahlt werden</li>
                    <li>
                        Nicht für den Immobilienkauf geeignet, sondern für den Hausbau, wo mehrere vergleichsweise
                        kleine Summen anfallen
                    </li>
                </ul>
                <hr />

                <h2>Welche Kosten fallen an?</h2>
                <p>
                    Auch das Baukonto ist eine Art von{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    , deshalb fallen auch hierfür{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    an. Der verrechnete Sollzins ist meistens etwas höher als bei langfristigen Wohnbaufinanzierungen,
                    aber das ist bei der überschaubaren Kreditlaufzeit logisch und gut planbar. Die Kontoführungsgebühr
                    ist hingegen meist eher günstig. Am Anfang wird übrigens eine einmalige Bearbeitungsgebühr bezahlt.
                    Und auch an die Grundbucheintragungsgebühren solltest du denken, falls deine Bank sich
                    grundbücherlich besichern lassen möchte. Darüber solltest du dich im Vorhinein informieren. Einen
                    ersten Überblick über die voraussichtlichen Kosten eines Kredits kannst du dir mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    verschaffen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Check dir einen Beratungstermin!
                    </a>
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Ein Baukonto ist bei allen größeren Bauprojekten äußerst sinnvoll und praktisch. Um deinen{" "}
                    <Link to="/artikel/hausbau-kosten/" target="_blank" rel="noreferrer noopener">
                        Hausbau
                    </Link>{" "}
                    zu realisieren musst du nicht ewig auf die Wohnbauförderung warten, sondern schon vorher beginnen.
                    Um das richtige Baukonto für dich zu finden, solltest du aber ungefähr wissen, wie lange dein
                    Bauprojekt dauern wird und dich vorher über verschiedene Angebote beraten lassen.
                </p>
            </Article>

            <BreadcrumbList page={"baukonto"}></BreadcrumbList>
            <ArticleStructuredData page={"baukonto"} heading={"Alles Wichtige zum Thema Baukonto"} />
        </Layout>
    );
};

export default Baukonto;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.baukonto", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
